import PropTypes from "prop-types";
import React from "react";

import { Ring, Boleto, Money, SpinnerPurple, DownloadReceipt } from "../../../../assets/svgs/index";
import useRequest from "../../../../hooks/useRequest";
import { getCompanySvg } from "../../../../utils/creditCardCompany";
import { getMonthYear } from "../../../../utils/dates";
import { getColorByStatus, getStatusName } from "../../../../utils/status";
import { downloadFile } from "../../../../utils/utils";

const HistoryLevy = ({ utc, price, status, charge, billId }) => {
  const { request } = useRequest();

  const [isLoading, setIsLoading] = React.useState(false);

  const downloadReceipt = async () => {
    setIsLoading(true);
    const { pdf } = await request("GET", "/user/getReceipt/" + billId);

    setIsLoading(false);
    downloadFile(pdf, "recibo");
  };

  return (
    <div className="bg-white p-7 rounded-xl border shadow-lg">
      <p className="capitalize">{getMonthYear(utc)}</p>
      <div className="flex justify-between">
        <p>R${price}</p>

        {charge.payment_method.code === "credit_card" && (
          <div className="flex items-center gap-1 text-xs">
            <p>••••{charge.last_transaction?.payment_profile?.card_number_last_four ?? "####"}</p>
            {getCompanySvg(charge.last_transaction?.payment_profile?.payment_company?.id)}
          </div>
        )}

        {charge.payment_method.code === "bank_slip" && (
          <div className="flex item-center gap-2">
            <Boleto />
            <p>Boleto Bancário</p>
          </div>
        )}

        {charge.payment_method.code === "cash" && (
          <div className="flex item-center gap-2">
            <Money />
            <p>Baixa manual</p>
          </div>
        )}
      </div>
      <hr className="my-1 border-gray-300" />
      <div className="flex items-center gap-1 mt-3">
        <span className={`text-${getColorByStatus(status)}-500`}>
          <Ring />
        </span>
        <div>{getStatusName(status)}</div>
        {status === "paid" && (
          <div style={{ marginLeft: "auto" }}>
            {!isLoading && <DownloadReceipt onClick={downloadReceipt} className="cursor-pointer" />}
            {isLoading && <SpinnerPurple className="w-5 animate-spin" />}
          </div>
        )}
      </div>
    </div>
  );
};

HistoryLevy.propTypes = {
  utc: PropTypes.string,
  price: PropTypes.string,
  status: PropTypes.string,
  charge: PropTypes.object,
  billId: PropTypes.string,
};

export default HistoryLevy;
