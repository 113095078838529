import PropTypes from "prop-types";
import React, { useContext, useState } from "react";

import { CloseX } from "../../../../../assets/svgs/index";
import { SlideContext } from "../../../../../context/SlideContext";
import CardBankBillPayment from "../CardBankBillPayment/CardBankBillPayment";
import CreditCardPayment from "../CreditCardPayment/CreditCardPayment";

const CardPayment = ({ planUuid, isReactivatePlan, planType }) => {
  const { setShowSlide } = useContext(SlideContext);
  const [key, setkey] = useState(1);

  return (
    <div style={{ width: "800px" }}>
      <div className="flex justify-between">
        <h1>Contratar plano</h1>
        <button onClick={() => setShowSlide(false)}>
          <CloseX />
        </button>
      </div>
      <p>Qual será a forma de pagamento?</p>
      <div className="grid grid-cols-2 p-3">
        <button
          className={`border-b-2 p-3 ${key === 1 && "border-aqua-green font-aqua-green"}`}
          onClick={() => setkey(1)}>
          {window.innerWidth < 1366 ? "Cartão" : "Cartão de Crédito"}
        </button>
        <button
          className={`border-b-2 p-3 ${key === 2 && "border-aqua-green font-aqua-green"}`}
          onClick={() => setkey(2)}>
          Boleto Báncario
        </button>
      </div>
      {key === 1 && (
        <CreditCardPayment
          planType={planType}
          isReactivatePlan={isReactivatePlan}
          planUuid={planUuid}
          setShowSlide={setShowSlide}
        />
      )}
      {key === 2 && (
        <CardBankBillPayment
          planType={planType}
          isReactivatePlan={isReactivatePlan}
          planUuid={planUuid}
          setShowSlide={setShowSlide}
        />
      )}
    </div>
  );
};

CardPayment.propTypes = {
  planUuid: PropTypes.string,
  isReactivatePlan: PropTypes.bool,
  planType: PropTypes.string,
};

export default CardPayment;
