import { ReactComponent as AppleButton } from "assets/svgs/apple_button.svg";
import { ReactComponent as GoogleButtonSvg } from "assets/svgs/google_button.svg";
import { useChatbot } from "context/ChatbotContext";
import { UserContext } from "context/UserContext";
import React, { useContext, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { logAnalyticsEvent } from "utils/firebase-analytics";
import { TooltipDic } from "utils/utils";

import Chat from "./components/Chat/Chat";
import Tooltip from "./components/Tooltip/Tooltip";
import * as S from "./styles.module.css";

const isMobile = window.innerWidth < 768;

const ChatBot = () => {
  const { mood, message, showTooltip, setShowTooltip, showChat, setShowChat, showTutor, setShowTutor } = useChatbot();
  const { user } = useContext(UserContext);

  const [showModal, setShowModal] = useState(false);
  const [initialMessages, setInitialMessages] = useState([]);

  const handleClick = (key, value) => {
    if (key === "showTooltip") {
      setShowTooltip(value);
    }

    if (key === "showTutor") {
      setShowTutor(value);
    }

    sessionStorage.setItem(key, value);
  };

  const handleChatClick = () => {
    setShowChat(true);
    logAnalyticsEvent("BAY_click");
  };

  useEffect(() => {
    if (!user?.role) {
      return;
    }

    const initialMessagesMock = [
      {
        type: "Caption",
      },
      {
        type: "QuickReplies",
        props: {
          message: "Eu sou a Bay, uma IA criada para te ensinar inglês de forma personalizada. Vamos começar?",
          replies: [
            { text: "Quero minha revisão personalizada", value: "Quero uma revisão com IA" },
            { text: "Me ajude com uma dúvida de inglês", value: "Quero tirar uma dúvida sobre inglês" },
            { text: "Quero falar com o suporte", value: "Quero falar com o suporte" },
          ],
        },
      },
    ];

    if (user.role === "LEAD") {
      initialMessagesMock[1].props.replies.unshift({
        text: "Liberar cupom de desconto",
        value: "Liberar cupom de desconto",
      });
    }

    if (user.role === "STUDENT") {
      initialMessagesMock[1].props.replies.unshift({
        text: "Quero praticar conversação com você",
        value: "Quero praticar conversação com você",
      });
    }

    setInitialMessages(initialMessagesMock);
  }, [user?.role]);

  return (
    <>
      {showModal && (
        <div className="plans__bg flex flex-col justify-center items-center">
          <div style={{ maxWidth: 320 }} className="relative bg-white flex flex-col items-center p-6 rounded-lg gap-4">
            <IoMdClose
              style={{ top: 10, left: 10 }}
              className="absolute cursor-pointer"
              size={22}
              onClick={() => setShowModal(false)}
            />

            <h3 className="mt-4">Para praticar conversação comigo</h3>

            {isMobile ? (
              <div className="flex flex-col justify-center items-center gap-2">
                <a href="https://play.google.com/store/apps/details?id=com.englishbay.App&utm_source=modal-plataforma">
                  <GoogleButtonSvg />
                </a>

                <a href="https://apps.apple.com/br/app/englishbay-curso-de-inglês/id6450712168?utm_source=modal-plataforma">
                  <AppleButton />
                </a>

                <p className="text-center">Baixe o aplicativo na loja e comece e converse comigo!</p>
              </div>
            ) : (
              <>
                <img style={{ height: 170 }} src="/images/qr_code.png" alt="QR Code" />
                <p className="text-center">Leia o código QR e baixe o aplicativo da EnglishBay</p>
              </>
            )}
          </div>
        </div>
      )}

      <div
        className={S.Container}
        style={{
          zIndex: showChat ? 1000 : 500,
        }}>
        {initialMessages.length && (
          <Chat
            initialMessages={initialMessages}
            showChat={showChat}
            setShowChat={setShowChat}
            setShowModal={setShowModal}
          />
        )}

        {!showChat && showTutor && (
          <div className="relative flex flex-col items-end gap-2">
            {showTooltip && message && (
              <Tooltip
                text={message}
                onClick={() => {
                  logAnalyticsEvent("BAY_close_tooltip");
                  handleClick("showTooltip", false);
                }}
              />
            )}

            {(!showTooltip || !message) && (
              <button
                className={S.Close}
                onClick={() => {
                  logAnalyticsEvent("BAY_close");
                  handleClick("showTutor", false);
                }}>
                <IoMdClose color="white" />
              </button>
            )}

            <button onClick={handleChatClick} className={S.ChatButton}>
              <img src={TooltipDic[mood].url} alt="gif" />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatBot;
