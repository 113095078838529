import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import QuestionCard from "../QuestionCard/QuestionCard";
import QuestionCardPhoneNumber from "../QuestionCard/QuestionCardPhoneNumber";
import { commonQuestions, learnAloneQuestion, scheduleClass } from "./OnBoardingQuestions";

import "./OnBoardingForm.css";

const OnBoardingForm = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [reason, setReason] = useState(null);
  const [age, setAge] = useState(null);
  const [interest, setInterest] = useState(null);
  const [preference, setPreference] = useState(null);
  const [wantTestClass, setWantClass] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [level, setLevel] = useState(null);
  const [userPhoneNumber, setPhoneNumber] = useState();

  const setters = [setOrigin, setReason, setAge, setInterest, setLevel, setPreference, setWantClass];
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (origin !== null) {
      setCurrentQuestion(currentQuestion + 1);

      const onBoardingData = {
        origin,
        reason,
        age,
        interest,
        preference,
        wantTestClass,
        userPhoneNumber,
        level,
      };

      localStorage.setItem("userData", JSON.stringify(onBoardingData));

      if (userPhoneNumber) {
        history.push("/onboarding/signup");
      }

      if (preference === "Teste de nível") {
        history.push("/onboarding/level");
      }

      if (preference === "Começar do zero") {
        history.push("/onboarding/signup");
      }

      if (wantTestClass === "Não") {
        history.push("/onboarding/signup");
      }
    }
  }, [origin, reason, age, interest, preference, wantTestClass, userPhoneNumber, level]);

  const saveOriginCode = () => {
    const originCode = new URLSearchParams(location.search).get("originCode");

    if (originCode) {
      localStorage.setItem("originCode", originCode);
    }
  };

  useEffect(() => {
    logAnalyticsEvent("OB_start");
    saveOriginCode();
  }, []);

  const progress = wantTestClass === "Sim" ? (currentQuestion / 6) * 100 : (currentQuestion / 5) * 100;

  return (
    <div className="main-wrapper">
      <div className="progress-bar">
        <div className="progress-fill" style={{ width: `${progress}%` }}>
          <div className="fill-glow"></div>
        </div>
      </div>

      <div className="questions-container">
        <div className="questions" style={{ transform: `translateX(${currentQuestion * -100}%)` }}>
          {commonQuestions.map((question, index) => (
            <QuestionCard data={question} setter={setters[index]} key={index} />
          ))}

          {interest === "Aprender sozinho" && <QuestionCard data={learnAloneQuestion} setter={setters[5]} />}

          {interest === "Professor particular" && <QuestionCard data={scheduleClass} setter={setWantClass} />}

          {wantTestClass === "Sim" && (
            <QuestionCardPhoneNumber
              title="Nos fale seu WhatsApp para agendarmos sua aula teste gratuita!"
              setter={setPhoneNumber}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OnBoardingForm;
